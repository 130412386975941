<template>
    <div class="login-container">
        <div class="content-block">
            <div class="title">易信网客户客户关系管理系统</div>
            <el-form :model="formData" :rules="rules" class="form" ref="formRef" v-if="isUserInfo">
                <div class="label">用户名</div>
                <el-form-item prop="username">
                    <el-input
                        v-model="formData.username"
                        placeholder="请输入用户名"
                        prefix-icon="el-icon-user-solid"
                    ></el-input>
                </el-form-item>
                <div class="label">密码</div>
                <el-form-item prop="password">
                    <el-input
                        v-model="formData.password"
                        placeholder="请输入密码"
                        :type="type"
                        prefix-icon="el-icon-lock"
                        tabindex="2"
                        auto-complete="on"
                    >
                        <template #suffix>
                            <i
                                class="el-icon-view"
                                @click="() => type = 'text'"
                                v-if="type == 'password'"
                            ></i>
                            <img
                                class="view-block"
                                src="../../assets/images/password-not-view.png"
                                alt
                                @click="() => type = 'password'"
                                v-if="type == 'text'"
                            />
                        </template>
                    </el-input>
                </el-form-item>
                <el-button type="primary" @click="onSubmit">登录</el-button>
            </el-form>
            <el-form
                :model="phoneData"
                :rules="phoneRules"
                class="form"
                ref="phoneRef"
                v-if="!isUserInfo"
            >
                <div class="label">登录手机号</div>
                <el-form-item prop="mobile">
                    <el-input
                        v-model="phoneData.mobile"
                        @keyup="validateMobile"
                        placeholder="请输入手机号"
                        prefix-icon="el-icon-mobile-phone"
                    ></el-input>
                </el-form-item>
                <div class="label">验证码</div>
                <el-form-item prop="code">
                    <div class="code-block">
                        <el-input
                            v-model="phoneData.code"
                            placeholder="请输入验证码"
                            prefix-icon="el-icon-message"
                        ></el-input>
                        <el-button
                            class="code-btn"
                            @click="handleCode"
                            :disabled="isDisabled"
                        >{{ isCountDown ? `${countdown}S` : '获取验证码' }}</el-button>
                    </div>
                </el-form-item>
                <el-button type="primary" @click="handleCodeSubmit">登录</el-button>
            </el-form>
            <div class="login-type" @click="toggleLogin"  v-if="isUserInfo">{{ loginMsg }}</div>
<!--            <div class="login-type" @click="toggleLogin">{{ loginMsg }}</div>-->
        </div>
    </div>
</template>

<script>
import {
    ElButton,
    ElForm,
    ElFormItem,
    ElIcon,
    ElInput,
    ElMessage,
} from "element-plus";
import { reactive, ref } from "vue";
import { login } from "@/api/method/login";
import { useRoute, useRouter } from "vue-router";
import { useStore } from "vuex";
import { validatePhone } from "../../until";
import { getCode, loginCode } from "../../api/method/login";

export default {
    name: "Login",
    components: {
        [ElForm.name]: ElForm,
        [ElFormItem.name]: ElFormItem,
        [ElInput.name]: ElInput,
        [ElButton.name]: ElButton,
        [ElIcon.name]: ElIcon,
    },
    setup () {
        localStorage.removeItem("token");
        localStorage.removeItem("routes");
        localStorage.removeItem("buttons");
        const type = ref("password");
        const route = useRoute();
        const router = useRouter();
        const { state, dispatch } = useStore();
        const rules = reactive({
            username: [
                { required: true, message: "请输入用户名", trigger: "submit" },
            ],
            password: [{ required: true, message: "请输入密码", trigger: "submit" }],
        });
        const formRef = ref(null);
        const formData = reactive({
            username: "",
            password: "",
        });
        const phoneRules = reactive({
            mobile: [
                {
                    required: true, validator: (rule, value, callback) =>
                        validatePhone(rule, value, callback), trigger: "submit"
                },
            ],
            code: [{ required: true, message: "请输入验证码", trigger: "submit" }],
        });
        const phoneRef = ref(null);
        const phoneData = reactive({
            mobile: "",
            code: "",
        });
        const onSubmit = () => {
            formRef.value.validate(async (valid) => {
                if (valid) {
                    const res = await login(formData);

                    if (res.code == 200) {
                        ElMessage.success("登录成功");
                        await initalRoutes();
                        localStorage.setItem("token", res.token);
                        localStorage.setItem("routes", routesArr);
                        localStorage.setItem("buttons", buttonArr);
                        route.query.redirect
                            ? router.push(route.query.redirect)
                            : router.push("/workbench");
                    } else {
                        ElMessage.error(res.msg);
                    }
                } else {
                    return false;
                }
            });
        };
        const routesArr = [];
        const buttonArr = [];
        const getRouters = (routes) => {
            routes.forEach((route) => {
                if (!route.isMenu && !Number(route.url)) {
                    routesArr.push(route.url);
                }
                if (route.userMenuModels.length) {
                    getRouters(route.userMenuModels);
                }
            });
        };

        const getButtons = (routes) => {
            routes.forEach((route) => {
                if (route.isMenu) {
                    buttonArr.push(route.menuName);
                }
                if (route.userMenuModels.length) {
                    getButtons(route.userMenuModels);
                }
            });
        };
        const initalRoutes = async () => {
            await dispatch("menuRouters");
            getRouters(state.menuRouters);
            getButtons(state.menuRouters);
        };

        const isUserInfo = ref(false);
        const isDisabled = ref(true);
        const loginMsg = ref("用户名密码登录");

        const toggleLogin = () => {
            isUserInfo.value
                ? (loginMsg.value = "用户名密码登录")
                : (loginMsg.value = "手机号验证码登录");
            isUserInfo.value = !isUserInfo.value;
        };
        /**获取验证码 */
        const uuid = ref(null)
        const isCountDown = ref(false)
        const countdown = ref(60)
        const handleCode = async () => {
            isCountDown.value = true
            isDisabled.value = true
            const timer = setInterval(() => {
                if (countdown.value == 0) {
                    isCountDown.value = false
                    isDisabled.value = false
                    countdown.value = 60
                    clearInterval(timer)
                }
                countdown.value -= 1
            }, 1000)
            const res = await getCode({mobile: phoneData.mobile})
            console.log(res)
            if(res.code == 200) {
                ElMessage.success('验证码发送成功！')
                uuid.value = res.uuid
            }else {
                ElMessage.error(res.msg)
            }
        }
        const validateMobile = () => {
            if (/^1(3|4|5|6|7|8)\d{9}$/.test(phoneData.mobile)) {
                isDisabled.value = false
            } else {
                isDisabled.value = true
            }
        }

        //验证码密码登录
        const handleCodeSubmit = () => {
            phoneRef.value.validate(async (valid) => {
                if (valid) {
                    const res = await loginCode({ ...phoneData, uuid: uuid.value });
                    if (res.code == 200) {
                        ElMessage.success("登录成功");
                        await initalRoutes();
                        localStorage.setItem("token", res.token);
                        localStorage.setItem("routes", routesArr);
                        localStorage.setItem("buttons", buttonArr);
                        route.query.redirect
                            ? router.push(route.query.redirect)
                            : router.push("/workbench");
                    } else {
                        ElMessage.error(res.msg);
                    }
                } else {
                    return false;
                }
            });
        }

        return {
            formData,
            type,
            rules,
            onSubmit,
            formRef,
            isUserInfo,
            isDisabled,
            loginMsg,
            toggleLogin,
            phoneRules,
            phoneData,
            phoneRef,
            handleCode,
            validateMobile,
            handleCodeSubmit,
            isCountDown,
            countdown
        };
    },
};
</script>

<style lang="scss">
@import "@/assets/css/variable.scss";

.login-container {
    min-width: 1350px;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    background-image: url("../../assets/images/login-bg.png");
    background-size: 100%;
    background-repeat: no-repeat;
    .content-block {
        width: 468px;
        height: 468px;
        background: #fff;
        box-shadow: 0 4px 51px 0 rgb(0 0 0 / 8%);
        border-radius: 8px;
        .title {
            font-size: 24px;
            font-weight: 600;
            color: rgba(0, 0, 0, 0.85);
            line-height: 22px;
            margin-bottom: 64px;
            text-align: center;
            padding-top: 70px;
        }
    }
    .form {
        padding: 0 50px;
        .label {
            line-height: 32px;
        }
        .el-input {
            line-height: 48px;
        }
        // .el-input__prefix {
        //   font-size: 18px;
        //   left: 10px;
        // }
        .el-input__suffix {
            font-size: 18px;
            cursor: pointer;
            img {
                width: 20px;
            }
        }
        // .el-input__suffix-inner {
        //   margin-right: 10px;
        // }
        .view-block {
            width: 20px;
            height: 20px;
            cursor: pointer;
        }
        .el-input__inner {
            -webkit-appearance: none;
            background-color: #fff;
            background-image: none;
            border-radius: 4px;
            border: 1px solid #dcdfe6;
            box-sizing: border-box;
            color: #606266;
            display: inline-block;
            font-size: inherit;
            height: 40px;
            line-height: 40px;
            outline: none;
            padding: 0 15px;
            transition: border-color 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
            width: 100%;
        }
        .el-input--prefix .el-input__inner {
            padding-left: 30px;
        }
        .code-block {
            display: flex;
            align-items: center;
            .code-btn {
                width: 112px;
                margin-left: 8px;
            }
        }
        .el-button {
            width: 100%;
            margin: 10px 0;
        }
    }
    .login-type {
        padding: 0 50px;
        color: $blue-02;
        cursor: pointer;
    }
    .el-button--primary {
        border-color: $blue-02;
        background: $blue-02;
    }
}
</style>